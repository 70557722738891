import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentContainer from "../../components/contentContainer"
import NewsletterForm from "../../components/newsletterForm"

class Sponsoren extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const node = data.allMarkdownRemark.edges[0].node

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={node.frontmatter.seoTitle || node.frontmatter.title} description={node.frontmatter.seoDescription} />
        <div className="content">
          <ContentContainer paddingTop paddingMobile paddingMobileTop>
            <NewsletterForm html={node.html} title={node.frontmatter.title}/>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default Sponsoren

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/newsletter/newsletter.md/"}}) {
      edges {
        node {
          html
          frontmatter {
            title
            seoTitle
            seoDescription
          }
        }
      }
    }
  }
`